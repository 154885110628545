import React, { useState } from 'react';

function TwitterDashboard() {
    // State to manage the downloading status
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

    // Function to handle the download of the CSV file
    const handleCsvDownload = () => {
        // Indicate that the CSV download is in progress
        setIsDownloadingCsv(true);

        try {
            // Specify the URL of the CSV file
            const csvFileUrl = `${process.env.PUBLIC_URL}/twitter_v1_normal.csv`;
            
            // Create a temporary link element for downloading the CSV file
            const a = document.createElement('a');
            a.href = csvFileUrl; // Set the href to the CSV file URL
            a.download = 'twitter_v1.csv'; // Set the desired file name for the download
            
            // Append the anchor to the body (needed for Firefox)
            document.body.appendChild(a);
            
            // Trigger the download by simulating a click on the anchor
            a.click();
            
            // Clean up by removing the anchor from the document
            a.remove();
        } catch (error) {
            // Log any errors that occur during the process
            console.error("Error downloading the CSV file:", error);
        } finally {
            // Reset the downloading state for the CSV download
            setIsDownloadingCsv(false);
        }
    };

    return (
        <div>
            {/* Button that triggers the CSV download process */}
            <button onClick={handleCsvDownload} disabled={isDownloadingCsv}>
                {isDownloadingCsv ? 'Downloading CSV...' : 'Download Twitter CSV'}
            </button>
            {isDownloadingCsv && <p>Please wait, your CSV file is being downloaded...</p>}
        </div>
    );
}

export default TwitterDashboard;

// import React, { useState } from 'react';

// function TwitterDashboard() {
//     // State to manage the downloading status
//     const [isDownloadingZip, setIsDownloadingZip] = useState(false);

//     // Function to handle the download of the ZIP file
//     const handleZipDownload = () => {
//         // Indicate that the ZIP download is in progress
//         setIsDownloadingZip(true);

//         try {
//             // Specify the URL of the ZIP file
//             const zipFileUrl = `${process.env.PUBLIC_URL}/twitter_v1.zip`;
            
//             // Create a temporary link element for downloading the ZIP file
//             const a = document.createElement('a');
//             a.href = zipFileUrl; // Set the href to the ZIP file URL
//             a.download = 'twitter_v1.zip'; // Set the desired file name for the download
            
//             // Append the anchor to the body (needed for Firefox)
//             document.body.appendChild(a);
            
//             // Trigger the download by simulating a click on the anchor
//             a.click();
            
//             // Clean up by removing the anchor from the document
//             a.remove();
//         } catch (error) {
//             // Log any errors that occur during the process
//             console.error("Error downloading the ZIP file:", error);
//         } finally {
//             // Reset the downloading state for the ZIP download
//             setIsDownloadingZip(false);
//         }
//     };

//     return (
//         <div>
//             {/* Button that triggers the ZIP download process */}
//             <button onClick={handleZipDownload} disabled={isDownloadingZip}>
//                 {isDownloadingZip ? 'Downloading ZIP...' : 'Download ZIP'}
//             </button>
//             {isDownloadingZip && <p>Please wait, your ZIP file is being downloaded...</p>}
//         </div>
//     );
// }

// export default TwitterDashboard;
