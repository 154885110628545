import React, { useState } from "react";

function LoginScreen({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Function to handle login
  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    setLoading(true);
    setError("");

    try {
      const response = await fetch('https://data-bank-israel-war-app.vercel.app/login/ilan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      if (!response.ok) {
        throw new Error('Invalid username or password');
      }

      const data = await response.json();
      console.log(data);
      onLogin(true); // Call onLogin if successful
      // Clear form after successful login
      setUsername("");
      setPassword("");
    } catch (error) {
      console.error('Error:', error);
      setError(error.message); // Set error message for user feedback
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="username"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
}

export default LoginScreen;
